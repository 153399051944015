import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'deleteAcquisition';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        deleteAcquisition: null,
    };
}
export const deleteAcquisition = createAsyncThunk('acquisition/delete', async (acquisitionId, { rejectWithValue }) => {
    try {
        const response = await api.delete(`/api/acquisition/delete/${acquisitionId}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const deleteAcquisitionSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetDeleteAcquisition: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.deleteAcquisition = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(deleteAcquisition.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(deleteAcquisition.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.deleteAcquisition = action.payload;
            })
            .addCase(deleteAcquisition.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetDeleteAcquisition } = deleteAcquisitionSlice.actions;
export const deleteAcquisitionReducer = deleteAcquisitionSlice.reducer;
