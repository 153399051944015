import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'getDifficultyById';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        getDifficultyById: null,
    };
}

export const getDifficultyById = createAsyncThunk('difficulty/getById', async (id, { rejectWithValue }) => {
    try {
        const response = await api.get(`/api/difficulty/get/${id}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const getDifficultyByIdSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetGetDifficultyById: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.getDifficultyById = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getDifficultyById.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(getDifficultyById.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.getDifficultyById = action.payload;
            })
            .addCase(getDifficultyById.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetGetDifficultyById } = getDifficultyByIdSlice.actions;
export const getDifficultyByIdReducer = getDifficultyByIdSlice.reducer;
