import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    Button,
    Container,
    Dimmer,
    Dropdown,
    Form,
    FormField,
    Icon,
    Image,
    Loader,
    Message,
    MessageHeader,
    Modal,
    ModalActions,
    ModalContent,
    Pagination,
    Segment,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from 'semantic-ui-react';
import Swal from 'sweetalert2';
import { FormatZuluTime } from '../../helpers/Formatter';
import { addTopic, resetAddTopic } from '../../store/topic/addTopic.slice';
import { deleteTopic, resetDeleteTopic } from '../../store/topic/deleteTopic.slice';
import { getTopics } from '../../store/topic/getTopic.slice';
import { getTopicByParentId } from '../../store/topic/getTopicByParentId.slice';
import { resetUpdateTopic, updateTopic } from '../../store/topic/updateTopic.slice';
import Header from '../layout/Header';

function Topics() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [t] = useTranslation();
    const addTopicState = useSelector(state => state.addTopic);
    const deleteTopicState = useSelector(state => state.deleteTopic);
    const getTopicByParentIdState = useSelector(state => state.getTopicByParentId);
    const getTopicsState = useSelector(state => state.getTopics);
    const updateTopicsState = useSelector(state => state.updateTopic);

    const [open, setOpen] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [topicList, setTopicList] = useState([]);
    const [parentId, setParentId] = useState(null);
    const [modalType, setModalType] = useState('add');
    const [loadingId, setLoadingId] = useState(null); // Durumu tutacak olan state
    const [totalRecords, setTotalRecords] = useState(0);
    const [topicId, setTopicId] = useState(null);
    const [topicTitle, setTopicTitle] = useState(null);
    const [topicDescription, setTopicDescription] = useState(null);
    let topics = [];

    useEffect(() => {
        setParentId(null);
        setTopicList([]);
        dispatch(getTopics({ pageSize, pageNumber }));
    }, [navigate]);

    useEffect(() => {
        dispatch(getTopics({ pageSize, pageNumber }));
    }, [dispatch, pageSize, pageNumber]);

    useEffect(() => {
        setParentId(null);
        setTopicList([]);
        dispatch(getTopics({ pageSize, pageNumber }));
    }, [location.pathname]); // URL değiştiğinde useEffect'i tetikle

    useEffect(() => {
        if (getTopicsState.done) {
            setTopicList([getTopicsState.getTopics.result.topics]);
        }
    }, [getTopicsState]);

    useEffect(() => {
        if (getTopicByParentIdState.done) {
            const newTopics = getTopicByParentIdState?.getTopicByParentId?.result?.topic;

            if (!newTopics || newTopics.length === 0) {
                return;
            }

            if (!topicList.some(list => list.some(topic => topic.ID === newTopics[0].parentId))) {
                setTopicList(prevList => [...prevList, newTopics]);
            }
        }
    }, [getTopicByParentIdState]);

    // useEffect(() => {
    //     if (getTopicByParentIdState.done) {
    //         const newTopics = getTopicByParentIdState?.getTopicByParentId?.result?.topic;
    //         if (newTopics && newTopics.length > 0) {
    //             setTopicList(prevList => [...prevList, newTopics]);
    //         }
    //     }
    // }, [getTopicByParentIdState]);

    const handleDropdownChange = (index, value) => {
        // Mevcut seviye ve üstündekileri koru, altındakileri kaldır
        const updatedList = topicList.slice(0, index + 1);
        setParentId(value);

        // Yeni seçilen acquisition'ın çocuklarını yüklemek için action'ı tetikle
        dispatch(getTopicByParentId(value));

        // Listeyi güncelle
        setTopicList(updatedList);
    };

    const handleDelete = id => {
        setLoadingId(id); // Silme işlemi başladığında, loadingId'yi güncelle
        dispatch(deleteTopic(id));
    };

    useEffect(() => {
        if (addTopicState.done) {
            setOpen(false);
            setTopicTitle('');
            setTopicDescription('');
            setTopicId(null);
            setLoadingId(null);
            setModalType('add');
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Başarılı',
                text: 'Topic başarıyla eklendi',
                showConfirmButton: false,
                timer: 1500,
            });
            if (parentId) {
                dispatch(getTopicByParentId(parentId));
            } else {
                dispatch(getTopics({ pageSize, pageNumber }));
            }
            dispatch(resetAddTopic());
            return;
        }
    }, [addTopicState]);

    useEffect(() => {
        if (updateTopicsState.done) {
            setOpen(false);
            setTopicTitle('');
            setTopicDescription('');
            setTopicId(null);
            setLoadingId(null);
            setModalType('add');
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Başarılı',
                text: 'Topic başarıyla güncellendi',
                showConfirmButton: false,
                timer: 1500,
            });
            if (parentId) {
                dispatch(getTopicByParentId(parentId));
            } else {
                dispatch(getTopics({ pageSize, pageNumber }));
            }
            dispatch(resetUpdateTopic());
            return;
        }
    }, [updateTopicsState]);

    useEffect(() => {
        if (deleteTopicState.done) {
            setLoadingId(null);
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Başarılı',
                text: 'Topic başarıyla silindi',
                showConfirmButton: false,
                timer: 1500,
            });
            if (parentId) {
                dispatch(getTopicByParentId(parentId));
            } else {
                dispatch(getTopics({ pageSize, pageNumber }));
            }
            dispatch(resetDeleteTopic());
            return;
        }
    }, [deleteTopicState.done]);

    if (getTopicByParentIdState?.done) {
        topics = getTopicByParentIdState.getTopicByParentId.result.topic;
    } else if (getTopicsState?.done) {
        topics = getTopicsState.getTopics.result.topics;
    }

    return (
        <div>
            <Header />
            <Container style={{ marginTop: `7em` }}>
                <Message textalign="left" attached className="d-flex justify-content-between align-items-center">
                    <MessageHeader>{t('topics')}</MessageHeader>
                    <Button
                        onClick={() => {
                            setModalType('addParent');
                            setOpen(true);
                        }}
                    >
                        {' '}
                        {t('addNewParent')}
                    </Button>
                </Message>
                <Form className="attached fluid segment">
                    {topicList.map((topics, index) => (
                        <Dropdown
                            className="mt-3"
                            key={index}
                            placeholder={t('selectTopic')}
                            fluid
                            search
                            selection
                            options={topics.map(acq => ({
                                key: acq.ID,
                                value: acq.ID,
                                text: acq.Title,
                            }))}
                            onChange={(e, data) => handleDropdownChange(index, data.value)}
                        />
                    ))}
                </Form>
                <Table celled>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>Id</TableHeaderCell>
                            <TableHeaderCell>{t('title')}</TableHeaderCell>
                            <TableHeaderCell>{t('description')}</TableHeaderCell>
                            <TableHeaderCell>{t('createdAt')}</TableHeaderCell>
                            <TableHeaderCell>{t('updatedAt')}</TableHeaderCell>

                            <TableHeaderCell textalign="right"></TableHeaderCell>
                            <TableHeaderCell textalign="right">
                                <Button
                                    onClick={() => {
                                        setModalType('add');
                                        setOpen(true);
                                    }}
                                    icon
                                >
                                    {t('addTopic')}
                                    <Icon name="add" />
                                </Button>
                            </TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {topics.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.ID}</TableCell>
                                <TableCell>{item.Title}</TableCell>
                                <TableCell>{item.Description}</TableCell>
                                <TableCell>{FormatZuluTime(item.CreatedAt)}</TableCell>
                                <TableCell>{FormatZuluTime(item.UpdatedAt)}</TableCell>
                                <TableCell textalign="right">
                                    <Button
                                        color="yellow"
                                        onClick={() => {
                                            setTopicId(item.ID);
                                            setTopicTitle(item.Title);
                                            setTopicDescription(item.Description);
                                            setModalType('update');
                                            setOpen(true);
                                        }}
                                        icon
                                    >
                                        {t('update')}
                                    </Button>
                                </TableCell>
                                <TableCell textalign="right">
                                    <Button
                                        color="red"
                                        onClick={() => handleDelete(item.ID)}
                                        icon
                                        loading={loadingId === item.ID && deleteTopicState.spinner}
                                    >
                                        {t('delete')}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                        {getTopicByParentIdState.spinner && (
                            <>
                                <TableRow>
                                    <TableCell colSpan="5">
                                        <Segment>
                                            <Dimmer active inverted>
                                                <Loader active size="large">
                                                    Yükleniyor
                                                </Loader>
                                            </Dimmer>

                                            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                                        </Segment>
                                    </TableCell>
                                </TableRow>
                            </>
                        )}
                    </TableBody>
                </Table>
                <Pagination
                    floated="right"
                    defaultActivePage={1}
                    firstItem={null}
                    lastItem={null}
                    onPageChange={(e, data) => {
                        setPageNumber(data.activePage);
                        dispatch(getTopics(pageSize, data.activePage));
                    }}
                    pointing
                    secondary
                    totalPages={Math.ceil(totalRecords / pageSize)}
                />
            </Container>
            <Modal closeIcon open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
                <ModalContent>
                    <Form>
                        <FormField>
                            <label>{t('title')}</label>
                            <input
                                value={topicTitle}
                                onChange={e => setTopicTitle(e.target.value)}
                                placeholder={t('title')}
                            />
                        </FormField>
                        <FormField>
                            <label>{t('description')}</label>
                            <input
                                value={topicDescription}
                                onChange={e => setTopicDescription(e.target.value)}
                                placeholder={t('description')}
                            />
                        </FormField>
                    </Form>
                </ModalContent>
                <ModalActions>
                    <Button color="red" onClick={() => setOpen(false)}>
                        <Icon name="remove" /> {t('cancel')}
                    </Button>
                    {modalType === 'add' ? (
                        <Button
                            color="green"
                            loading={addTopicState.spinner}
                            onClick={() => {
                                dispatch(
                                    addTopic({ title: topicTitle, description: topicDescription, parentId: parentId })
                                );
                                dispatch(getTopics({ pageSize, pageNumber }));
                            }}
                        >
                            <Icon name="add" /> {t('addTopic')}
                        </Button>
                    ) : modalType === 'update' ? (
                        <Button
                            color="green"
                            loading={updateTopicsState.spinner}
                            onClick={() => {
                                dispatch(
                                    updateTopic({
                                        id: topicId,
                                        title: topicTitle,
                                        description: topicDescription,
                                        parentId: parentId,
                                    })
                                );
                            }}
                        >
                            <Icon name="save" /> {t('updateTopic')}
                        </Button>
                    ) : modalType === 'addParent' ? (
                        <Button
                            color="green"
                            loading={addTopicState.spinner}
                            onClick={() => {
                                dispatch(
                                    addTopic({ title: topicTitle, description: topicDescription, parentId: null })
                                );
                            }}
                        >
                            <Icon name="add" /> {t('addParent')}
                        </Button>
                    ) : null}
                </ModalActions>
            </Modal>
        </div>
    );
}

export default Topics;
