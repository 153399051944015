import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'getTopics';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        getTopics: null,
    };
}

export const getTopics = createAsyncThunk('topics/get', async ({ pageSize, pageNumber }, { rejectWithValue }) => {
    try {
        const response = await api.get(`/api/topic/get/${pageSize}/${pageNumber}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const getTopicsSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetGetTopics: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.getTopics = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getTopics.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(getTopics.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.getTopics = action.payload;
            })
            .addCase(getTopics.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetGetTopics } = getTopicsSlice.actions;
export const getTopicsReducer = getTopicsSlice.reducer;
