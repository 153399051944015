import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'addDifficulty';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        addDifficulty: null,
    };
}

export const addDifficulty = createAsyncThunk('difficulty/add', async ({ title, content }, { rejectWithValue }) => {
    try {
        const response = await api.post('/api/difficulty/add', { title, content });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const addDifficultySlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetAddDifficulty: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.addDifficulty = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(addDifficulty.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(addDifficulty.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.addDifficulty = action.payload;
            })
            .addCase(addDifficulty.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetAddDifficulty } = addDifficultySlice.actions;
export const addDifficultyReducer = addDifficultySlice.reducer;
