import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react';
import Swal from 'sweetalert2';
import { createCode } from '../../store/auth/createCode.slice';

function CreateCode() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const createCodeState = useSelector(state => state.createCode);
    const [phoneNumber, setPhoneNumber] = useState('');

    useEffect(() => {
        if (createCodeState.done) {
            navigate('/verify-code');
            return;
        }
    }, [createCodeState.done, navigate]);

    useEffect(() => {
        if (createCodeState.error !== false) {
            const errorMessage = createCodeState?.error?.response?.data?.errorMessage;
            Swal.fire({
                title: 'Hata!',
                text: errorMessage ? errorMessage : 'Bir hata oluştu',
                icon: 'error',
                confirmButtonText: 'Tamam',
            });
        }
    }, [createCodeState.error]);

    function handleSubmit() {
        dispatch(createCode(phoneNumber));
    }

    return (
        <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
            <Grid.Column style={{ maxWidth: 450 }}>
                <Header as="h2" color="teal" textAlign="center"></Header>
                <Form size="large">
                    <Segment stacked>
                        <PhoneInput
                            id="phoneNumber"
                            className="mb-2 border border-1 text-start"
                            placeholder="Telefon numaranız"
                            name="phoneNumber"
                            country={'tr'}
                            inputStyle={{
                                width: `100% important!`,
                                border: `0px`,
                                paddingLeft: `45px`,
                            }}
                            value={phoneNumber}
                            enableSearch={false}
                            onChange={e => setPhoneNumber(e)}
                            onEnterKeyPress={() => handleSubmit()}
                        />

                        <Button
                            onClick={handleSubmit}
                            color="teal"
                            fluid
                            loading={createCodeState.spinner}
                            size="large"
                        >
                            Devam et
                        </Button>
                    </Segment>
                </Form>
                <Message>
                    Giriş yapamıyorum <a href="mailto:admin@peakway.com.tr">Yardımcı ol</a>
                </Message>
            </Grid.Column>
        </Grid>
    );
}

export default CreateCode;
