import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'updateProductionLine';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        updateProductionLine: null,
    };
}

export const updateProductionLine = createAsyncThunk(
    'productionLine/update',
    async (
        {
            acquisitionID,
            base,
            baseType,
            content,
            difficulty,
            id,
            maxTokens,
            questionTypeID,
            temperature,
            title,
            topP,
            topicID,
            withSolution,
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await api.put('/api/productionline/update', {
                acquisitionID,
                base,
                baseType,
                content,
                difficulty,
                id,
                maxTokens,
                questionTypeID,
                temperature,
                title,
                topP,
                topicID,
                withSolution,
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const updateProductionLineslice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetUpdateProductionLine: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.updateProductionLine = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(updateProductionLine.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(updateProductionLine.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.updateProductionLine = action.payload;
            })
            .addCase(updateProductionLine.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetUpdateProductionLine } = updateProductionLineslice.actions;
export const updateProductionLineReducer = updateProductionLineslice.reducer;
