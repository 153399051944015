import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'addAcquisition';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        addDifficulty: null,
    };
}
export const addAcquisition = createAsyncThunk(
    'acquisition/add',
    async ({ acquisition, description, parentId }, { rejectWithValue }) => {
        try {
            const response = await api.post('/api/acquisition/add', {
                acquisition,
                description,
                parentId,
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const addAcquisitionSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetAddAcquisition: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.addDifficulty = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(addAcquisition.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(addAcquisition.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.addDifficulty = action.payload;
            })
            .addCase(addAcquisition.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetAddAcquisition } = addAcquisitionSlice.actions;
export const addAcquisitionReducer = addAcquisitionSlice.reducer;
