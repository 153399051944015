import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'getProductionLines';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        getProductionLines: null,
    };
}

export const getProductionLines = createAsyncThunk('productionLines/get', async (_, { rejectWithValue }) => {
    try {
        const response = await api.get('/api/productionline/get');
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const getProductionLineslice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetGetProductionLines: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.getProductionLines = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getProductionLines.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(getProductionLines.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.getProductionLines = action.payload;
            })
            .addCase(getProductionLines.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetGetProductionLines } = getProductionLineslice.actions;
export const getProductionLinesReducer = getProductionLineslice.reducer;
