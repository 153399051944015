import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'updateTopic';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        updateTopic: null,
    };
}

export const updateTopic = createAsyncThunk(
    'topic/update',
    async ({ id, title, description, parentId }, { rejectWithValue }) => {
        try {
            const response = await api.put('/api/topic/update', { id, title, description, parentId });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const updateTopicSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetUpdateTopic: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.updateTopic = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(updateTopic.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(updateTopic.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.updateTopic = action.payload;
            })
            .addCase(updateTopic.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetUpdateTopic } = updateTopicSlice.actions;
export const updateTopicReducer = updateTopicSlice.reducer;
