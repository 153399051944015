import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'addQuestionType';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        addQuestionType: null,
    };
}

export const addQuestionType = createAsyncThunk('questionType/add', async ({ title, content }, { rejectWithValue }) => {
    try {
        const response = await api.post('/api/questiontype/add', { title, content });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const addQuestionTypeSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetAddQuestionType: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.addQuestionType = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(addQuestionType.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(addQuestionType.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.addQuestionType = action.payload;
            })
            .addCase(addQuestionType.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetAddQuestionType } = addQuestionTypeSlice.actions;
export const addQuestionTypeReducer = addQuestionTypeSlice.reducer;
