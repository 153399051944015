import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'getQuestionTypes';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        getQuestionTypes: null,
    };
}

export const getQuestionTypes = createAsyncThunk('questionTypes/get', async (_, { rejectWithValue }) => {
    try {
        const response = await api.get('/api/questiontype/get');
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const getQuestionTypesSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetGetQuestionTypes: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.getQuestionTypes = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getQuestionTypes.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(getQuestionTypes.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.getQuestionTypes = action.payload;
            })
            .addCase(getQuestionTypes.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetGetQuestionTypes } = getQuestionTypesSlice.actions;
export const getQuestionTypesReducer = getQuestionTypesSlice.reducer;
