import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardMeta,
    Container,
    Dimmer,
    Dropdown,
    Form,
    FormField,
    Grid,
    GridColumn,
    Header,
    Icon,
    Image,
    Label,
    List,
    ListContent,
    ListHeader,
    ListItem,
    Loader,
    Message,
    MessageContent,
    MessageHeader,
    MessageList,
    Modal,
    ModalActions,
    ModalContent,
    Segment,
} from 'semantic-ui-react';
import Swal from 'sweetalert2';
import { FormatZuluTime } from '../../helpers/Formatter';
import { getAcquisitions } from '../../store/acquisition/getAcquisitions.slice';
import { getAcquisitionsByParentId } from '../../store/acquisition/getAcquisitionsByParentId.slice';
import { getDifficulty } from '../../store/addDifficulty/getDifficulty.slice';
import { createQuestion } from '../../store/creator/createQuestion.slice';
import { addProductionLine, resetAddProductionLine } from '../../store/productionLine/addProductionLine.slice';
import { getProductionLines } from '../../store/productionLine/getProductionLines.slice';
import { updateProductionLine } from '../../store/productionLine/updateProductionLine.slice';
import { addQuestion, resetAddQuestion } from '../../store/question/addQuestion';
import { getQuestionTypes } from '../../store/questionType/getQuestionTypes.slice';
import { getTopics } from '../../store/topic/getTopic.slice';
import { getTopicByParentId } from '../../store/topic/getTopicByParentId.slice';
import LayoutHeader from '../layout/Header';
function ProductionLines() {
    /**STYLE */
    const style = {
        display: '-webkit-box',
        WebkitLineClamp: 5,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };
    /**STYLE END */

    const dispatch = useDispatch();
    const createQuestionState = useSelector(state => state.createQuestion);
    const addProductionLineState = useSelector(state => state.addProductionLine);
    const getProductionLinesState = useSelector(state => state.getProductionLines);
    const getAcquisitionsState = useSelector(state => state.getAcquisitions);
    const getAcquisitionsByParentIdState = useSelector(state => state.getAcquisitionsByParentId);
    const getTopicByParentIdState = useSelector(state => state.getTopicByParentId);
    const getTopicsState = useSelector(state => state.getTopics);
    const getQuestionTypesState = useSelector(state => state.getQuestionTypes);
    const getDifficultyState = useSelector(state => state.getDifficulty);
    const updateDifficultyState = useSelector(state => state.updateDifficulty);
    const addDifficultyState = useSelector(state => state.addDifficulty);
    const updateProductionLineState = useSelector(state => state.updateProductionLine);
    const [t] = useTranslation();

    const [open, setOpen] = useState(false);
    const [acquisitionsList, setAcquisitionsList] = useState([]);
    const [acquisitionParentId, setAcquisitionParentId] = useState(null);
    const [acuisitionId, setAcuisitionId] = useState(null);
    const [topicList, setTopicList] = useState([]);
    const [topicParentId, setTopicParentId] = useState(null);
    const [modalType, setModalType] = useState('add');
    const [base, setBase] = useState(null);
    const [baseType, setBaseType] = useState(null);
    const [content, setContent] = useState(null);
    const [maxTokens, setMaxTokens] = useState(null);
    const [temperature, setTemperature] = useState(null);
    const [title, setTitle] = useState(null);
    const [topP, setTopP] = useState(null);
    const [withSolution, setWithSolution] = useState(null);
    const [difficultyId, setDifficultyId] = useState(null);
    const [questionTypeId, setQuestionTypeId] = useState(null);
    const [visibilityStates, setVisibilityStates] = useState({});
    const [questionTypeOptions, setQuestionTypeOptions] = useState([]);
    const [acquisitionOptions, setAcquisitionOptions] = useState([]);
    const [topicOptions, setTopicOptions] = useState([]);
    const [difficultyOptions, setDifficultyOptions] = useState([]);

    const resetFormFields = () => {
        setTitle(null);
        setWithSolution(null);
        setAcquisitionParentId(null);
        setAcuisitionId(null);
        setTopicParentId(null);
        setBase(null);
        setBaseType(null);
        setContent(null);
        setMaxTokens(null);
        setTemperature(null);
        setTopP(null);
        setDifficultyId(null);
        setQuestionTypeId(null);
    };

    const updateQuestionTypeOptions = productionLine => {
        setOpen(true);
        setModalType('update');
        setTitle(productionLine.Title);
        setWithSolution(productionLine.WithSolution);
        setBaseType(productionLine.BaseType);
        setBase(productionLine.Base);
        setMaxTokens(productionLine.MaxTokens);
        setTemperature(productionLine.Temperature);
        setTopP(productionLine.TopP);
        setContent(productionLine.Content); // Eğer varsa
        setQuestionTypeId(productionLine.QuestionType.ID);
        setTopicParentId(productionLine.Topic.ID);
        setDifficultyId(productionLine.Difficulty.ID);
        setAcuisitionId(productionLine.Acquisition.ID);

        // Eğer dropdown'lar için veri yeniden yüklenmesi gerekiyorsa
        dispatch(getAcquisitions({ pageSize: 20, pageNumber: 0 }));
        dispatch(getTopics({ pageSize: 20, pageNumber: 0 }));
        dispatch(getQuestionTypes());
        dispatch(getDifficulty({ pageSize: 20, pageNumber: 0 }));
    };

    /** Acquisition */
    useEffect(() => {
        if (getAcquisitionsState.done) {
            setAcquisitionsList([getAcquisitionsState.getAcquisitions.result.acquisition]);
        }
    }, [getAcquisitionsState]);

    useEffect(() => {
        if (getAcquisitionsByParentIdState.done) {
            const newAcquisitions = getAcquisitionsByParentIdState?.getAcquisitionsByParentId?.result?.acquisition;
            if (!newAcquisitions || newAcquisitions.length === 0) {
                return;
            }

            if (!acquisitionsList.some(acquisitions => acquisitions[0].ParentID === newAcquisitions[0].ParentID)) {
                setAcquisitionsList(prevList => [...prevList, newAcquisitions]);
            }
        }
    }, [getAcquisitionsByParentIdState]);

    const handleDropdownChangeForAcquisition = (index, value) => {
        const updatedList = acquisitionsList.slice(0, index + 1);
        setAcquisitionParentId(value);
        dispatch(getAcquisitionsByParentId(value));
        setAcquisitionsList(updatedList);
    };

    /** Acquisition End */

    /** Topic */
    useEffect(() => {
        if (getTopicsState.done) {
            setTopicList([getTopicsState.getTopics.result.topics]);
        }
    }, [getTopicsState]);

    useEffect(() => {
        if (getTopicByParentIdState.done) {
            const newTopics = getTopicByParentIdState?.getTopicByParentId?.result?.topic;
            if (!newTopics || newTopics.length === 0) {
                return;
            }

            if (!topicList.some(topics => topics[0].ParentID === newTopics[0].ParentID)) {
                setTopicList(prevList => [...prevList, newTopics]);
            }
        }
    }, [getTopicByParentIdState]);

    const handleDropdownChangeForTopic = (index, value) => {
        const updatedList = topicList.slice(0, index + 1);
        setTopicParentId(value);
        dispatch(getTopicByParentId(value));
        setTopicList(updatedList);
    };

    /** Topic End */

    useEffect(() => {
        if (addProductionLineState.done) {
            setOpen(false);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Production Line added successfully',
            });
            dispatch(getProductionLines(20, 0));
            dispatch(resetAddProductionLine());
        }
    }, [addProductionLineState, dispatch]);

    useEffect(() => {
        if (updateProductionLineState.done) {
            setOpen(false);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Production Line updated successfully',
            });
            dispatch(getProductionLines(20, 0));
            dispatch(resetAddProductionLine());
        }
    }, [updateProductionLineState, dispatch]);

    useEffect(() => {
        dispatch(getProductionLines());
    }, [dispatch]);

    useEffect(() => {
        if (createQuestionState.done) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Question created successfully',
            });
            createQuestionState?.createQuestion?.result?.result?.questions?.map((question, index) => {
                dispatch(
                    addQuestion({
                        content: question.question,
                        options: JSON.stringify(question.options),
                        rightAnswer: question.rightAnswer,
                        solution: null,
                        productionLineId: 1,
                        isPublished: false,
                    })
                );
                return null;
            });
            const initialVisibilityStates = {};
            createQuestionState?.createQuestion?.result?.result?.questions?.forEach((question, index) => {
                initialVisibilityStates[index] = true; // Tüm mesajları başlangıçta görünür yap
            });
            setVisibilityStates(initialVisibilityStates);

            // dispatch(resetCreateQuestion());
            dispatch(resetAddQuestion());
        }
    }, [createQuestionState, dispatch]);

    const handleDismiss = index => {
        setVisibilityStates(prevState => ({
            ...prevState,
            [index]: false, // Sadece tıklanan mesajı gizle
        }));
    };

    return (
        <div>
            <LayoutHeader />
            <Container style={{ marginTop: `7em` }}>
                <Segment clearing>
                    <Header as="h4" floated="right">
                        <Button
                            onClick={() => {
                                setOpen(true);
                                resetFormFields();
                                setModalType('add');
                                dispatch(getAcquisitions({ pageSize: 20, pageNumber: 0 }));
                                dispatch(getDifficulty({ pageSize: 20, pageNumber: 0 }));
                                dispatch(getQuestionTypes());
                                dispatch(getTopics({ pageSize: 20, pageNumber: 0 }));
                            }}
                        >
                            <Icon name="plus" />
                            {t('addNewProductionLine')}
                        </Button>
                    </Header>
                    <Header as="h2" floated="left">
                        {t('questionProductionLines')}
                    </Header>
                </Segment>
                {createQuestionState?.createQuestion?.result?.result?.questions?.map(
                    (question, index) =>
                        visibilityStates[index] && (
                            <Message key={index} onDismiss={() => handleDismiss(index)} className="text-start">
                                <MessageHeader>
                                    <b>Soru :</b> {question.question}
                                </MessageHeader>
                                <MessageList className="mt-2">
                                    <b>A) </b> {question.options['A)']}
                                    <br />
                                    <b>B) </b> {question.options['B)']}
                                    <br />
                                    <b>C) </b> {question.options['C)']}
                                    <br />
                                    <b>D) </b>
                                    {question.options['D)']}
                                    <br />
                                    <b>E) </b>
                                    {question.options['E)']}
                                </MessageList>
                                <MessageContent className="mt-4">
                                    <b>Doğru Cevap :</b> {question.rightAnswer}
                                </MessageContent>
                            </Message>
                        )
                )}

                <Grid columns={4}>
                    {getProductionLinesState.done &&
                        getProductionLinesState.getProductionLines.result.productionLines.map(
                            (productionLine, index) => (
                                <GridColumn mobile={16} tablet={8} computer={4} key={index}>
                                    <Card>
                                        <CardContent textAlign="left">
                                            <CardHeader>
                                                <Button
                                                    color="teal"
                                                    floated="right"
                                                    onClick={() => {
                                                        updateQuestionTypeOptions(productionLine);
                                                    }}
                                                >
                                                    <Icon name="edit" />
                                                    {t('update')}
                                                </Button>
                                                {productionLine.Title}
                                            </CardHeader>
                                            <CardMeta>{FormatZuluTime(productionLine.CreatedAt)}</CardMeta>
                                            <CardDescription>
                                                <List celled>
                                                    <ListItem>
                                                        <ListContent className="mt-2">
                                                            <ListHeader className="mb-2">Temperature</ListHeader>
                                                            {productionLine.Temperature}
                                                        </ListContent>
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListContent className="mt-2">
                                                            <ListHeader className="mb-2">{t('maxTokens')}</ListHeader>
                                                            {productionLine.MaxTokens}
                                                        </ListContent>
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListContent className="mt-2">
                                                            <ListHeader className="mb-2">
                                                                {t('withSolution')}
                                                            </ListHeader>
                                                            <Label
                                                                color={productionLine.WithSolution ? `green` : `red`}
                                                            >
                                                                {productionLine.WithSolution ? t('yes') : t('no')}
                                                            </Label>
                                                        </ListContent>
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListContent className="mt-2">
                                                            <ListHeader className="mb-2">{t('acquisition')}</ListHeader>
                                                            {productionLine.Acquisition?.Acquisition}
                                                        </ListContent>
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListContent className="mt-2">
                                                            <ListHeader className="mb-2">{t('difficulty')}</ListHeader>
                                                            {productionLine.Difficulty?.Title}
                                                        </ListContent>
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListContent className="mt-2">
                                                            <ListHeader className="mb-2">{t('topic')}</ListHeader>
                                                            {productionLine.Topic?.Title}
                                                        </ListContent>
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListContent className="mt-2">
                                                            <ListHeader className="mb-2">
                                                                {t('questionType')}
                                                            </ListHeader>
                                                            {productionLine.QuestionType?.Title}
                                                        </ListContent>
                                                    </ListItem>
                                                    <ListItem className="pb-3">
                                                        <ListContent className="mt-2">
                                                            <ListHeader className="mb-3">{t('base')}</ListHeader>
                                                            <p style={style}>{productionLine.Base}</p>
                                                            <Button size="tiny" floated="right">
                                                                Devamı...
                                                            </Button>
                                                        </ListContent>
                                                    </ListItem>
                                                </List>
                                            </CardDescription>
                                        </CardContent>
                                        <CardContent extra>
                                            <div className="ui action input">
                                                <input type="text" placeholder={t('numberOfQuestions')} />
                                                <Button
                                                    loading={createQuestionState.spinner}
                                                    onClick={() => {
                                                        dispatch(
                                                            createQuestion(
                                                                productionLine.Base,
                                                                productionLine.Difficulty?.Content,
                                                                productionLine.Title,
                                                                productionLine.Topic?.Title,
                                                                productionLine.QuestionType?.Title,
                                                                productionLine.Acquisition?.Acquisition,
                                                                productionLine.MaxTokens,
                                                                productionLine.Temperature,
                                                                productionLine.TopP,
                                                                productionLine.WithSolution,
                                                                productionLine.BaseType
                                                            )
                                                        );
                                                    }}
                                                    className="ui button"
                                                >
                                                    {t('create')}
                                                </Button>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </GridColumn>
                            )
                        )}
                </Grid>

                <Grid divided>
                    {getProductionLinesState.spinner && (
                        <GridColumn>
                            <Segment>
                                <Dimmer active inverted>
                                    <Loader inverted content={t('loading')} />
                                </Dimmer>

                                <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                            </Segment>
                        </GridColumn>
                    )}
                </Grid>
            </Container>
            <Modal closeIcon open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
                <ModalContent>
                    <Form>
                        <FormField>
                            <label>{t('title')}</label>
                            <input onChange={e => setTitle(e.target.value)} placeholder={t('title')} value={title} />
                        </FormField>
                        <FormField>
                            <label>{t('withSolution')}</label>
                            <Dropdown
                                className="mt-3"
                                placeholder={t('withSolution')}
                                fluid
                                search
                                selection
                                value={withSolution}
                                options={
                                    [
                                        {
                                            key: 1,
                                            value: true,
                                            text: t('yes'),
                                        },
                                        {
                                            key: 2,
                                            value: false,
                                            text: t('no'),
                                        },
                                    ] || []
                                }
                                onChange={(e, data) => {
                                    setWithSolution(data.value);
                                }}
                            />
                        </FormField>
                        <FormField>
                            <label>{t('acquisition')}</label>
                            {getAcquisitionsState.spinner && (
                                <GridColumn>
                                    <Segment>
                                        <Dimmer active inverted>
                                            <Loader inverted content={t('loading')} />
                                        </Dimmer>

                                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                                    </Segment>
                                </GridColumn>
                            )}
                            {getAcquisitionsState.done &&
                                acquisitionsList.map((acquisitions, index) => (
                                    <Dropdown
                                        className="mt-3"
                                        key={index}
                                        placeholder={t('selectAcquisition')}
                                        fluid
                                        search
                                        selection
                                        value={acuisitionId}
                                        options={
                                            acquisitions.map(acq => ({
                                                key: acq.ID,
                                                value: acq.ID,
                                                text: acq.Acquisition,
                                            })) || []
                                        }
                                        onChange={(e, data) => {
                                            setAcuisitionId(data.value);
                                            handleDropdownChangeForAcquisition(index, data.value);
                                        }}
                                    />
                                ))}
                        </FormField>
                        <FormField>
                            <label>{t('topics')}</label>
                            {getTopicsState.spinner && (
                                <GridColumn>
                                    <Segment>
                                        <Dimmer active inverted>
                                            <Loader inverted content={t('loading')} />
                                        </Dimmer>

                                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                                    </Segment>
                                </GridColumn>
                            )}
                            {getTopicsState.done &&
                                topicList.map((topics, index) => (
                                    <Dropdown
                                        className="mt-3"
                                        key={index}
                                        placeholder={t('selectTopic')}
                                        fluid
                                        search
                                        selection
                                        value={topicParentId}
                                        options={
                                            topics.map(acq => ({
                                                key: acq.ID,
                                                value: acq.ID,
                                                text: acq.Title,
                                            })) || []
                                        }
                                        onChange={(e, data) => {
                                            setTopicParentId(data.value);
                                            handleDropdownChangeForTopic(index, data.value);
                                        }}
                                    />
                                ))}
                        </FormField>
                        <FormField>
                            <label>{t('difficulty')}</label>
                            <Dropdown
                                className="mt-3"
                                placeholder={t('selectDifficulty')}
                                fluid
                                search
                                selection
                                value={difficultyId}
                                options={
                                    getDifficultyState?.getDifficulty?.result?.difficulties?.map(acq => ({
                                        key: acq.ID,
                                        value: acq.ID,
                                        text: acq.Title,
                                    })) || []
                                }
                                onChange={(e, data) => {
                                    setDifficultyId(data.value);
                                }}
                            />
                        </FormField>
                        <FormField>
                            <label>{t('questionType')}</label>
                            <Dropdown
                                className="mt-3"
                                placeholder={t('selectQuestionType')}
                                fluid
                                search
                                selection
                                value={questionTypeId}
                                options={
                                    getQuestionTypesState?.getQuestionTypes?.result?.questionTypes?.map(acq => ({
                                        key: acq.ID,
                                        value: acq.ID,
                                        text: acq.Title,
                                    })) || []
                                }
                                onChange={(e, data) => {
                                    setQuestionTypeId(data.value);
                                }}
                            />
                        </FormField>
                        <FormField>
                            <label>{t('baseType')}</label>
                            <Dropdown
                                className="mt-3"
                                placeholder={t('creatorBaseType')}
                                fluid
                                search
                                selection
                                value={baseType}
                                options={
                                    [
                                        {
                                            key: 1,
                                            value: 'subject',
                                            text: 'Subject',
                                        },
                                        {
                                            key: 2,
                                            value: 'question',
                                            text: 'Question',
                                        },
                                    ] || []
                                }
                                onChange={(e, data) => {
                                    setBaseType(data.value);
                                }}
                            />
                        </FormField>
                        <FormField>
                            <label>{t('base')}</label>
                            <textarea
                                key={3}
                                onChange={e => setBase(e.target.value)}
                                placeholder={t('base')}
                                value={base}
                            />
                        </FormField>
                        <FormField>
                            <label>{t('maxTokens')}</label>
                            <input
                                type="number"
                                onChange={e => {
                                    var intValue = parseInt(e.target.value);
                                    setMaxTokens(intValue);
                                }}
                                placeholder={t('maxTokens')}
                                value={maxTokens}
                            />
                        </FormField>
                        <FormField>
                            <label>Temperature</label>
                            <input
                                onChange={e => {
                                    var intValue = parseFloat(e.target.value);
                                    setTemperature(intValue);
                                }}
                                placeholder="Temperature"
                                value={temperature}
                            />
                        </FormField>

                        <FormField>
                            <label>Top P</label>
                            <input
                                onChange={e => {
                                    var intValue = parseFloat(e.target.value);
                                    setTopP(intValue);
                                }}
                                placeholder="Top P"
                                value={topP}
                            />
                        </FormField>
                    </Form>
                </ModalContent>
                <ModalActions>
                    <Button color="red" onClick={() => setOpen(false)}>
                        <Icon name="remove" /> {t('cancel')}
                    </Button>
                    {modalType === 'add' ? (
                        <Button
                            color="green"
                            loading={addDifficultyState.spinner}
                            onClick={() => {
                                dispatch(
                                    addProductionLine({
                                        acquisitionID: acuisitionId,
                                        base: base,
                                        baseType: baseType,
                                        content: content,
                                        difficultyID: difficultyId,
                                        maxTokens: maxTokens,
                                        questionTypeID: questionTypeId,
                                        temperature: temperature,
                                        title: title,
                                        topP: topP,
                                        topicID: topicParentId,
                                        withSolution: withSolution,
                                    })
                                );
                            }}
                        >
                            <Icon name="add" /> {t('addNewProductionLine')}
                        </Button>
                    ) : modalType === 'update' ? (
                        <Button
                            color="green"
                            loading={updateDifficultyState.spinner}
                            onClick={() => {
                                dispatch(
                                    updateProductionLine({
                                        acquisitionID: acuisitionId,
                                        base: base,
                                        baseType: baseType,
                                        content: content,
                                        difficulty: difficultyId,
                                        maxTokens: maxTokens,
                                        questionTypeID: questionTypeId,
                                        temperature: temperature,
                                        title: title,
                                        topP: topP,
                                        topicID: topicParentId,
                                        withSolution: withSolution,
                                    })
                                );
                            }}
                        >
                            <Icon name="save" /> {t('updateNewProductionLine')}
                        </Button>
                    ) : null}
                </ModalActions>
            </Modal>
        </div>
    );
}

export default ProductionLines;
