import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'getAcquisitionThree';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        getAcquisitionThree: null,
    };
}

export const getAcquisitionThree = createAsyncThunk('acquisition/getThree', async (_, { rejectWithValue }) => {
    try {
        const response = await api.get(`/api/acquisition/three`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const getAcquisitionThreeSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetGetAcquisitionThree: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.getAcquisitionThree = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getAcquisitionThree.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(getAcquisitionThree.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.getAcquisitionThree = action.payload;
            })
            .addCase(getAcquisitionThree.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetGetAcquisitionThree } = getAcquisitionThreeSlice.actions;
export const getAcquisitionThreeReducer = getAcquisitionThreeSlice.reducer;
