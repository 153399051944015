import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Card,
    Checkbox,
    Container,
    Dimmer,
    Image,
    List,
    ListItem,
    Loader,
    Pagination,
    Segment,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from 'semantic-ui-react';
import Swal from 'sweetalert2';
import { getProductionLines } from '../../store/productionLine/getProductionLines.slice';
import { deleteQuestions, resetDeleteQuestions } from '../../store/question/deleteQuestions.slice';
import { getQuestionByPld } from '../../store/question/getQuestionsByPLD.slice';
import Header from '../layout/Header';

function Questions() {
    const dispatch = useDispatch();
    const deleteQuestionsState = useSelector(state => state.deleteQuestions);
    const getQuestionByPLDState = useSelector(state => state.getQuestionsByPLD);

    const getProductionLinesState = useSelector(state => state.getProductionLines);
    const [productionLines, setProductionLines] = useState([]);
    const [productionLineId, setProductionLineId] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedQuestions, setSelectedQuestions] = useState([]);

    useEffect(() => {
        dispatch(getProductionLines());
    }, [dispatch]);

    useEffect(() => {
        if (getProductionLinesState.done) {
            setProductionLines(getProductionLinesState.getProductionLines.result.productionLines);
        }
    }, [getProductionLinesState]);

    useEffect(() => {
        if (getQuestionByPLDState.done) {
            setQuestions(getQuestionByPLDState?.getQuestionsByPld?.result?.questions);
            setTotalRecords(getQuestionByPLDState?.getQuestionsByPld?.result?.totalCount);
        }
    }, [getQuestionByPLDState]);

    useEffect(() => {
        if (deleteQuestionsState.done) {
            Swal.fire({
                icon: 'success',
                title: 'Questions deleted successfully',
                showConfirmButton: false,
                timer: 1500,
            });
            dispatch(resetDeleteQuestions());
            dispatch(
                getQuestionByPld({ productionLineId: productionLineId, pageSize: pageSize, pageNumber: pageNumber })
            );
        }
    }, [deleteQuestionsState]);

    const handleCheckboxChange = questionId => {
        setSelectedQuestions(prevSelectedQuestions =>
            prevSelectedQuestions.includes(questionId)
                ? prevSelectedQuestions.filter(id => id !== questionId)
                : [...prevSelectedQuestions, questionId]
        );
    };

    return (
        <div>
            <Header />
            <Container style={{ marginTop: `7em` }} className="pb-5">
                {productionLines.map((productionLine, index) => {
                    return (
                        <Card
                            key={productionLine.ID}
                            className="text-start"
                            link
                            onClick={() => {
                                setProductionLineId(productionLine.ID);
                                dispatch(
                                    getQuestionByPld({
                                        productionLineId: productionLine.ID,
                                        pageSize: pageSize,
                                        pageNumber: pageNumber,
                                    })
                                );
                            }}
                            color="grey"
                            active="true"
                            header={productionLine.Title}
                            meta={productionLine.Acquisition.Acquisition}
                            description={productionLine.Base.slice(0, 130) + '...'}
                        />
                    );
                })}
                {getQuestionByPLDState.spinner && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted content="Loading" />
                        </Dimmer>

                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}
                {questions.length > 0 && (
                    <Table compact celled>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell></TableHeaderCell>
                                <TableHeaderCell>Question Content</TableHeaderCell>
                                <TableHeaderCell>Question Right Answer</TableHeaderCell>
                                <TableHeaderCell>Question Answers</TableHeaderCell>
                                <TableHeaderCell>Created At</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {questions?.map((question, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell collapsing>
                                            <Checkbox
                                                slider
                                                onChange={() => handleCheckboxChange(question.ID)} // Sorunun benzersiz ID'si veya index'i
                                                checked={selectedQuestions.includes(question.ID)}
                                            />
                                        </TableCell>
                                        <TableCell>{question.Content}</TableCell>
                                        <TableCell textAlign="center">{question.RightAnswer}</TableCell>
                                        <TableCell>
                                            <List as="ul">
                                                <ListItem as="li">
                                                    <b>A) </b> {JSON.parse(question.Options)['A)']}
                                                </ListItem>
                                                <ListItem as="li">
                                                    <b>B) </b> {JSON.parse(question.Options)['B)']}
                                                </ListItem>
                                                <ListItem as="li">
                                                    <b>C) </b> {JSON.parse(question.Options)['C)']}
                                                </ListItem>
                                                <ListItem as="li">
                                                    <b>D) </b> {JSON.parse(question.Options)['D)']}
                                                </ListItem>
                                                <ListItem as="li">
                                                    <b>E) </b> {JSON.parse(question.Options)['E)']}
                                                </ListItem>
                                                {/* {Object.entries(JSON.parse(question.Options)).map((option, index) => (
                                            <ListItem key={index} as='li'>{option}</ListItem>
                                        ))}
                                        {console.log(JSON.parse(question.Options))} */}
                                            </List>
                                        </TableCell>
                                        <TableCell>4 Mart 2024 16:47:07</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>

                        <TableFooter fullWidth>
                            <TableRow>
                                <TableHeaderCell />
                                <TableHeaderCell colSpan="4">
                                    <Button
                                        size="small"
                                        onClick={() => {
                                            Swal.fire({
                                                title: 'Are you sure?',
                                                text: 'You will not be able to recover this question!',
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonText: 'Yes, publish it!',
                                                cancelButtonText: 'No, keep it',
                                            }).then(result => {
                                                if (result.isConfirmed) {
                                                    // dispatch(deleteQuestions(selectedQuestions));
                                                }
                                            });
                                        }}
                                    >
                                        Publish
                                    </Button>
                                    <Button
                                        size="small"
                                        onClick={() => {
                                            Swal.fire({
                                                title: 'Are you sure?',
                                                text: 'You will not be able to recover this question!',
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonText: 'Yes, delete it!',
                                                cancelButtonText: 'No, keep it',
                                            }).then(result => {
                                                if (result.isConfirmed) {
                                                    dispatch(deleteQuestions(selectedQuestions));
                                                }
                                            });
                                        }}
                                        color="red"
                                    >
                                        Delete
                                    </Button>
                                    <Pagination
                                        floated="right"
                                        defaultActivePage={1}
                                        firstItem={null}
                                        lastItem={null}
                                        onPageChange={(e, data) => {
                                            setPageNumber(data.activePage);
                                            dispatch(
                                                getQuestionByPld({
                                                    productionLineId: productionLineId,
                                                    pageSize: pageSize,
                                                    pageNumber: data.activePage,
                                                })
                                            );
                                        }}
                                        pointing
                                        secondary
                                        totalPages={Math.ceil(totalRecords / pageSize)}
                                    />
                                </TableHeaderCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                )}
            </Container>
        </div>
    );
}

export default Questions;
