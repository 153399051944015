import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'getProductionLineById';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        getProductionLineById: null,
    };
}
export const getProductionLineById = createAsyncThunk(
    'productionLine/getById',
    async (productionLineId, { rejectWithValue }) => {
        try {
            const response = await api.get(`/api/productionline/get/${productionLineId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const getProductionLineByIdlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetGetProductionLineById: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.getProductionLineById = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getProductionLineById.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(getProductionLineById.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.productionLineId = action.payload;
            })
            .addCase(getProductionLineById.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetgetProductionLineById } = getProductionLineByIdlice.actions;
export const getProductionLineByIdReducer = getProductionLineByIdlice.reducer;
