import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'updateDifficulty';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        updateDifficulty: null,
    };
}

export const updateDifficulty = createAsyncThunk(
    'difficulty/update',
    async ({ id, title, content }, { rejectWithValue }) => {
        try {
            const response = await api.put(`/api/difficulty/update`, { id, title, content });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const updateDifficultySlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetUpdateDifficulty: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.updateDifficulty = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(updateDifficulty.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(updateDifficulty.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.updateDifficulty = action.payload;
            })
            .addCase(updateDifficulty.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetUpdateDifficulty } = updateDifficultySlice.actions;
export const updateDifficultyReducer = updateDifficultySlice.reducer;
