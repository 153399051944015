import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'getDifficulty';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        getDifficulty: null,
    };
}

export const getDifficulty = createAsyncThunk(
    'difficulty/get',
    async ({ pageSize, pageNumber }, { rejectWithValue }) => {
        try {
            const response = await api.get(`/api/difficulty/get/${pageSize}/${pageNumber}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const getDifficultySlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetGetDifficulty: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.getDifficulty = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getDifficulty.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(getDifficulty.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.getDifficulty = action.payload;
            })
            .addCase(getDifficulty.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetGetDifficulty } = getDifficultySlice.actions;
export const getDifficultyReducer = getDifficultySlice.reducer;
