import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Container,
    Dimmer,
    Form,
    FormField,
    Icon,
    Image,
    Loader,
    Modal,
    ModalActions,
    ModalContent,
    Pagination,
    Segment,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from 'semantic-ui-react';
import Swal from 'sweetalert2';
import { getDifficulty } from '../../store/addDifficulty/getDifficulty.slice';
import { addQuestionType, resetAddQuestionType } from '../../store/questionType/addQuestionType.slice';
import { deleteQuestionType, resetDeleteQuestionType } from '../../store/questionType/deleteQuestionType.slice';
import { getQuestionTypes } from '../../store/questionType/getQuestionTypes.slice';
import { resetUpdateQuestionType, updateQuestionType } from '../../store/questionType/updateQuestionType.slice';
import Header from '../layout/Header';
function QuestionTypes() {
    const dispatch = useDispatch();
    const addQuestionTypeState = useSelector(state => state.addQuestionType);
    const deleteQuestionTypeState = useSelector(state => state.deleteQuestionType);
    const getQuestionTypesState = useSelector(state => state.getQuestionTypes);
    const updateQuestionTypeState = useSelector(state => state.updateQuestionType);
    const [t] = useTranslation();

    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [loadingId, setLoadingId] = useState(null); // Durumu tutacak olan state
    const [questionTypeId, setQuestionTypeId] = useState(null);
    const [modalType, setModalType] = useState('add');
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        dispatch(getQuestionTypes());
    }, [dispatch]);

    useEffect(() => {
        // if (getQuestionTypesState.done) {
        //     setTotalRecords(getDifficultyState.getDifficulty.result.total);
        // }
        if (addQuestionTypeState.done) {
            setOpen(false);
            setTitle('');
            setContent('');
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Başarılı',
                text: 'Zorluk seviyesi eklendi',
                showConfirmButton: false,
                timer: 1500,
            });
            dispatch(getQuestionTypes());
            dispatch(resetAddQuestionType());
            return;
        }
        if (deleteQuestionTypeState.done) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Başarılı',
                text: 'Zorluk seviyesi silindi',
                showConfirmButton: false,
                timer: 1500,
            });
            dispatch(resetDeleteQuestionType());
            dispatch(getQuestionTypes());
            return;
        }

        if (updateQuestionTypeState.done) {
            setOpen(false);
            setTitle('');
            setContent('');
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Başarılı',
                text: 'Zorluk seviyesi güncellendi',
                showConfirmButton: false,
                timer: 1500,
            });
            dispatch(resetUpdateQuestionType());
            dispatch(getQuestionTypes());
            return;
        }
    }, [addQuestionTypeState, deleteQuestionTypeState, updateQuestionTypeState, getQuestionTypesState]);

    const handleDelete = id => {
        setLoadingId(id); // Silme işlemi başladığında, loadingId'yi güncelle
        dispatch(deleteQuestionType(id)).then(() => {
            setLoadingId(null); // Silme işlemi tamamlandığında, loadingId'yi sıfırla
        });
    };

    return (
        <div>
            <Header />
            <Container style={{ marginTop: `7em` }}>
                <Table celled>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>Id</TableHeaderCell>
                            <TableHeaderCell>{t('title')}</TableHeaderCell>
                            <TableHeaderCell>{t('content')}</TableHeaderCell>

                            <TableHeaderCell textAlign="right"></TableHeaderCell>
                            <TableHeaderCell textAlign="right">
                                <Button
                                    onClick={() => {
                                        setModalType('add');
                                        setOpen(true);
                                    }}
                                    icon
                                >
                                    {t('addQuestionType')}
                                    <Icon name="add" />
                                </Button>
                            </TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {getQuestionTypesState?.getQuestionTypes?.result?.questionTypes?.map((item, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{item.ID}</TableCell>
                                    <TableCell>{item.Title}</TableCell>
                                    <TableCell>{item.Content}</TableCell>

                                    <TableCell textAlign="right">
                                        <Button
                                            color="yellow"
                                            onClick={() => {
                                                setOpen(true);
                                                setModalType('update');
                                                setQuestionTypeId(item.ID);
                                                setTitle(item.Title);
                                                setContent(item.Content);
                                            }}
                                            icon
                                        >
                                            {t('update')}
                                        </Button>
                                    </TableCell>
                                    <TableCell textAlign="right">
                                        <Button
                                            color="red"
                                            onClick={() => handleDelete(item.ID)}
                                            icon
                                            loading={loadingId === item.ID && deleteQuestionTypeState.spinner}
                                        >
                                            {t('delete')}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {getQuestionTypesState.spinner && (
                            <>
                                <TableRow>
                                    <TableCell colSpan="5">
                                        <Segment>
                                            <Dimmer active inverted>
                                                <Loader active size="large">
                                                    {t('loading')}
                                                </Loader>
                                            </Dimmer>

                                            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                                        </Segment>
                                    </TableCell>
                                </TableRow>
                            </>
                        )}
                    </TableBody>
                </Table>
                <Pagination
                    floated="right"
                    defaultActivePage={1}
                    firstItem={null}
                    lastItem={null}
                    onPageChange={(e, data) => {
                        setPageNumber(data.activePage);
                        dispatch(getDifficulty({ pageSize: pageSize, pageNumber: data.activePage }));
                    }}
                    pointing
                    secondary
                    totalPages={Math.ceil(totalRecords / pageSize)}
                />
            </Container>
            <Modal closeIcon open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
                <ModalContent>
                    <Form>
                        <FormField>
                            <label>{t('title')}</label>
                            <input value={title} onChange={e => setTitle(e.target.value)} placeholder={t('title')} />
                        </FormField>
                        <FormField>
                            <label>{t('value')}</label>
                            <input
                                value={content}
                                onChange={e => setContent(e.target.value)}
                                placeholder={t('content')}
                            />
                        </FormField>
                    </Form>
                </ModalContent>
                <ModalActions>
                    <Button color="red" onClick={() => setOpen(false)}>
                        <Icon name="remove" /> {t('cancel')}
                    </Button>
                    {modalType === 'add' ? (
                        <Button
                            color="green"
                            loading={addQuestionTypeState.spinner}
                            onClick={() => {
                                dispatch(addQuestionType({ title, content }));
                            }}
                        >
                            <Icon name="add" /> {t('addQuestionType')}
                        </Button>
                    ) : modalType === 'update' ? (
                        <Button
                            color="green"
                            loading={updateQuestionTypeState.spinner}
                            onClick={() => {
                                dispatch(updateQuestionType({ id: questionTypeId, title: title, content: content }));
                            }}
                        >
                            <Icon name="save" /> {t('updateQuestionType')}
                        </Button>
                    ) : null}
                </ModalActions>
            </Modal>
        </div>
    );
}

export default QuestionTypes;
