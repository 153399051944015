import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Grid, Header, Icon, Message, Segment } from 'semantic-ui-react';

import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import { verifyCode } from '../../store/auth/verifyCode.slice';

function VerifyCode() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const verifyCodeState = useSelector(state => state.verifyCode);

    const [code, setCode] = useState('');

    useEffect(() => {
        if (verifyCodeState.done) {
            navigate('/');
        }
        if (verifyCodeState.error !== false) {
            Swal.fire({
                title: 'Hata!',
                text: verifyCodeState?.error?.response?.data?.errorMessage,
                icon: 'error',
                confirmButtonText: 'Tamam',
            });
        }
    }, [verifyCodeState, navigate, verifyCodeState?.error?.response?.data?.errorMessage]);

    function handleSubmit() {
        const phoneNumber = localStorage.getItem('phoneNumber');
        dispatch(verifyCode({ phoneNumber, verifyCode: code }));
    }

    return (
        <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
            <Grid.Column style={{ maxWidth: 450 }}>
                <Header as="h2" color="teal" textAlign="left">
                    <Icon name="shield alternate"></Icon> Doğrulama kodunu giriniz
                </Header>
                <Form size="large">
                    <Segment stacked>
                        <Form.Input
                            fluid
                            icon="check circle outline"
                            iconPosition="left"
                            placeholder="Doğrulama kodunuz"
                            onChange={e => setCode(e.target.value)}
                        />
                        <Button
                            onClick={handleSubmit}
                            color="teal"
                            fluid
                            loading={verifyCodeState.spinner}
                            size="large"
                        >
                            Devam et
                        </Button>
                    </Segment>
                </Form>
                <Message>
                    Giriş yapamıyorum <a href="mailto:admin@peakway.com.tr">Yardımcı ol</a>
                </Message>
            </Grid.Column>
        </Grid>
    );
}

export default VerifyCode;
