import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'addTopic';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        addTopic: null,
    };
}

export const addTopic = createAsyncThunk('topic/add', async ({ title, description, parentId }, { rejectWithValue }) => {
    try {
        const response = await api.post('/api/topic/add', { title, description, parentId });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const addTopicSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetAddTopic: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.addTopic = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(addTopic.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(addTopic.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.addTopic = action.payload;
            })
            .addCase(addTopic.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetAddTopic } = addTopicSlice.actions;
export const addTopicReducer = addTopicSlice.reducer;
