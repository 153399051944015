import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'createQuestion';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        createQuestion: null,
    };
}

export const createQuestion = createAsyncThunk(
    'question/create',
    async (
        {
            base,
            difficulty,
            lesson,
            subject,
            questionType,
            acquisition,
            maxTokens,
            temperature,
            topP,
            withSolution,
            baseType,
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await api.post('/api/question/create', {
                base,
                difficulty,
                lesson,
                subject,
                questionType,
                acquisition,
                maxTokens,
                temperature,
                topP,
                withSolution,
                baseType,
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const createQuestionSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetCreateQuestion: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.createQuestion = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(createQuestion.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(createQuestion.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.createQuestion = action.payload;
            })
            .addCase(createQuestion.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetCreateQuestion } = createQuestionSlice.actions;
export const createQuestionReducer = createQuestionSlice.reducer;
