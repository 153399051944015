import { configureStore } from '@reduxjs/toolkit';

import { addAcquisitionReducer } from './acquisition/addAcquisition.slice';
import { deleteAcquisitionReducer } from './acquisition/deleteAcquisition.slice';
import { getAcquisitionByIdReducer } from './acquisition/getAcquisitionById.slice';
import { getAcquisitionThreeReducer } from './acquisition/getAcquisitionThree.slice';
import { getAcquisitionReducer } from './acquisition/getAcquisitions.slice';
import { getAcquisitionSByParentIdReducer } from './acquisition/getAcquisitionsByParentId.slice';
import { updateAcquisitionReducer } from './acquisition/updateAcquisition.slice';
import { addDifficultyReducer } from './addDifficulty/addDifficulty.slice';
import { deleteDifficultyReducer } from './addDifficulty/deleteDifficulty.slice';
import { getDifficultyReducer } from './addDifficulty/getDifficulty.slice';
import { getDifficultyByIdReducer } from './addDifficulty/getDifficultyById.slice';
import { updateDifficultyReducer } from './addDifficulty/updateDifficulty.slice';
import { createCodeReducer } from './auth/createCode.slice';
import { verifyCodeReducer } from './auth/verifyCode.slice';
import { createQuestionReducer } from './creator/createQuestion.slice';
import { addProductionLineReducer } from './productionLine/addProductionLine.slice';
import { deleteProductionLineReducer } from './productionLine/deleteProductionLine.slice';
import { getProductionLineByIdReducer } from './productionLine/getProductionLineById.slice';
import { getProductionLinesReducer } from './productionLine/getProductionLines.slice';
import { updateProductionLineReducer } from './productionLine/updateProductionLine.slice';
import { addQuestionReducer } from './question/addQuestion';
import { deleteQuestionsReducer } from './question/deleteQuestions.slice';
import { getQuestionsByPLDReducer } from './question/getQuestionsByPLD.slice';
import { addQuestionTypeReducer } from './questionType/addQuestionType.slice';
import { deleteQuestionTypeReducer } from './questionType/deleteQuestionType.slice';
import { getQuestionTypeByIdReducer } from './questionType/getQuestionTypeById.slice';
import { getQuestionTypesReducer } from './questionType/getQuestionTypes.slice';
import { updateQuestionTypeReducer } from './questionType/updateQuestionType.slice';
import { addTopicReducer } from './topic/addTopic.slice';
import { deleteTopicReducer } from './topic/deleteTopic.slice';
import { getTopicsReducer } from './topic/getTopic.slice';
import { getTopicByIdReducer } from './topic/getTopicById.slice';
import { getTopicByParentIdReducer } from './topic/getTopicByParentId.slice';
import { updateTopicReducer } from './topic/updateTopic.slice';

export const store = configureStore({
    reducer: {
        addAcquisition: addAcquisitionReducer,
        deleteAcquisition: deleteAcquisitionReducer,
        getAcquisitionById: getAcquisitionByIdReducer,
        getAcquisitions: getAcquisitionReducer,
        getAcquisitionsByParentId: getAcquisitionSByParentIdReducer,
        getAcquisitionThree: getAcquisitionThreeReducer,
        updateAcquisition: updateAcquisitionReducer,
        createCode: createCodeReducer,
        verifyCode: verifyCodeReducer,
        createQuestion: createQuestionReducer,
        addProductionLine: addProductionLineReducer,
        deteleProductionLine: deleteProductionLineReducer,
        getProductLineById: getProductionLineByIdReducer,
        getProductionLines: getProductionLinesReducer,
        updateProductionLine: updateProductionLineReducer,
        addTopic: addTopicReducer,
        deleteTopic: deleteTopicReducer,
        getTopicById: getTopicByIdReducer,
        getTopicByParentId: getTopicByParentIdReducer,
        getTopics: getTopicsReducer,
        updateTopic: updateTopicReducer,
        addQuestionType: addQuestionTypeReducer,
        deleteQuestionType: deleteQuestionTypeReducer,
        getQuestionTyprById: getQuestionTypeByIdReducer,
        getQuestionTypes: getQuestionTypesReducer,
        updateQuestionType: updateQuestionTypeReducer,
        addDifficulty: addDifficultyReducer,
        deleteDifficulty: deleteDifficultyReducer,
        getDifficulty: getDifficultyReducer,
        getDifficultyById: getDifficultyByIdReducer,
        updateDifficulty: updateDifficultyReducer,
        addQuestion: addQuestionReducer,
        deleteQuestions: deleteQuestionsReducer,
        getQuestionsByPLD: getQuestionsByPLDReducer,
    },
});
