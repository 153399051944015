import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Container, Dropdown, Menu } from 'semantic-ui-react';
import { resetCreateCode } from '../../store/auth/createCode.slice';
import { logout } from '../../store/auth/verifyCode.slice';

function Header() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [t, i18n] = useTranslation();

    const verifyCodeState = useSelector(state => state.verifyCode);

    const changeLanguage = async lng => {
        await i18n.changeLanguage(lng);
    };

    useEffect(() => {
        dispatch(resetCreateCode());
    });

    const HandleLogout = () => {
        dispatch(logout());
        // dispatch(resetCreateCode());
        navigate('/');
        navigate('/login');
    };

    useEffect(() => {
        if (!verifyCodeState.isLogin) {
            navigate('/login');
        }
    }, [verifyCodeState.isLogin, navigate]);

    return (
        <div>
            <Menu stackable fixed="top">
                <Container>
                    <Menu.Item as="a"></Menu.Item>
                    <Menu.Item active={location.pathname === '/' ? true : false} as={Link} to="/">
                        {t('homepage')}
                    </Menu.Item>
                    <Dropdown item simple text={t('productionLine')}>
                        <Dropdown.Menu>
                            <Dropdown.Header>{t('categories')}</Dropdown.Header>
                            <Dropdown.Item as={Link} to="/topics">
                                {t('topic')}
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} to="/acquisitions">
                                {t('acquisition')}
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Header>{t('qreatorConfig')}</Dropdown.Header>
                            <Dropdown.Item as={Link} to="/question-types">
                                {t('questionType')}
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} to="/difficulties">
                                {t('difficulty')}
                            </Dropdown.Item>
                            <Dropdown.Header>{t('qreator')}</Dropdown.Header>
                            <Dropdown.Item as={Link} to="/production-lines">
                                {t('lines')}
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} to="/questions">
                                {t('question')}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Menu.Item as="a">{t('configuration')}</Menu.Item>

                    <Menu.Item position="right">
                        <Dropdown
                            as="a"
                            button
                            className="icon"
                            floating
                            defaultValue="Turkish"
                            labeled
                            icon="world"
                            options={[
                                {
                                    key: 'Turkish',
                                    text: 'Türkçe',
                                    value: 'Turkish',
                                    onClick: () => changeLanguage('tr'),
                                },
                                {
                                    key: 'English',
                                    text: 'English',
                                    value: 'English',
                                    onClick: () => changeLanguage('en'),
                                },
                            ]}
                            search
                        />
                        <Button onClick={HandleLogout} as="a" primary={true} style={{ marginLeft: '0.5em' }}>
                            <i className="fa-solid fa-arrow-right-from-bracket" style={{ marginRight: `5px` }}></i>{' '}
                            {t('logout')}
                        </Button>
                    </Menu.Item>
                </Container>
            </Menu>
            <Outlet />
        </div>
    );
}

export default Header;
