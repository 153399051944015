import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Container,
    Dimmer,
    Form,
    FormField,
    Icon,
    Image,
    Loader,
    Modal,
    ModalActions,
    ModalContent,
    Pagination,
    Segment,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from 'semantic-ui-react';
import Swal from 'sweetalert2';
import { addDifficulty, resetAddDifficulty } from '../../store/addDifficulty/addDifficulty.slice';
import { deleteDifficulty, resetDeleteDifficulty } from '../../store/addDifficulty/deleteDifficulty.slice';
import { getDifficulty } from '../../store/addDifficulty/getDifficulty.slice';
import { resetUpdateDifficulty, updateDifficulty } from '../../store/addDifficulty/updateDifficulty.slice';
import Header from '../layout/Header';

function Difficulties() {
    const dispatch = useDispatch();
    const addDifficultyState = useSelector(state => state.addDifficulty);
    const deleteDifficultyState = useSelector(state => state.deleteDifficulty);
    const getDifficultyState = useSelector(state => state.getDifficulty);
    const updateDifficultyState = useSelector(state => state.updateDifficulty);
    const [t] = useTranslation();

    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [loadingId, setLoadingId] = useState(null); // Durumu tutacak olan state
    const [difficultyId, setDifficultyId] = useState(null);
    const [modalType, setModalType] = useState('add');
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        dispatch(getDifficulty({ pageSize, pageNumber }));
    }, [dispatch]);

    useEffect(() => {
        if (getDifficultyState.done) {
            setTotalRecords(getDifficultyState.getDifficulty.result.total);
        }

        if (addDifficultyState.done) {
            setOpen(false);
            setTitle('');
            setContent('');
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Başarılı',
                text: 'Zorluk seviyesi eklendi',
                showConfirmButton: false,
                timer: 1500,
            });
            dispatch(getDifficulty({ pageSize, pageNumber }));
            dispatch(resetAddDifficulty());
            return;
        }
        if (deleteDifficultyState.done) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Başarılı',
                text: 'Zorluk seviyesi silindi',
                showConfirmButton: false,
                timer: 1500,
            });
            dispatch(resetDeleteDifficulty());
            dispatch(getDifficulty({ pageSize, pageNumber }));
            return;
        }

        if (updateDifficultyState.done) {
            setOpen(false);
            setTitle('');
            setContent('');
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Başarılı',
                text: 'Zorluk seviyesi güncellendi',
                showConfirmButton: false,
                timer: 1500,
            });
            dispatch(resetUpdateDifficulty());
            dispatch(getDifficulty({ pageSize, pageNumber }));
            return;
        }
    }, [addDifficultyState, deleteDifficultyState, updateDifficultyState, getDifficultyState]);

    const handleDelete = id => {
        setLoadingId(id); // Silme işlemi başladığında, loadingId'yi güncelle
        dispatch(deleteDifficulty(id)).then(() => {
            setLoadingId(null); // Silme işlemi tamamlandığında, loadingId'yi sıfırla
        });
    };

    return (
        <div>
            <Header />
            <Container style={{ marginTop: `7em` }}>
                <Table celled>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>Id</TableHeaderCell>
                            <TableHeaderCell>{t('title')}</TableHeaderCell>
                            <TableHeaderCell>{t('content')}</TableHeaderCell>

                            <TableHeaderCell textAlign="right"></TableHeaderCell>
                            <TableHeaderCell textAlign="right">
                                <Button
                                    onClick={() => {
                                        setModalType('add');
                                        setOpen(true);
                                    }}
                                    icon
                                >
                                    {t('addDifficulty')}
                                    <Icon name="add" />
                                </Button>
                            </TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {getDifficultyState?.getDifficulty?.result?.difficulties?.map((item, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{item.ID}</TableCell>
                                    <TableCell>{item.Title}</TableCell>
                                    <TableCell>{item.Content}</TableCell>

                                    <TableCell textAlign="right">
                                        <Button
                                            color="yellow"
                                            onClick={() => {
                                                setOpen(true);
                                                setModalType('update');
                                                setDifficultyId(item.ID);
                                                setTitle(item.Title);
                                                setContent(item.Content);
                                            }}
                                            icon
                                        >
                                            {t('update')}
                                        </Button>
                                    </TableCell>
                                    <TableCell textAlign="right">
                                        <Button
                                            color="red"
                                            onClick={() => handleDelete(item.ID)}
                                            icon
                                            loading={loadingId === item.ID && deleteDifficultyState.spinner}
                                        >
                                            {t('delete')}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {getDifficultyState.spinner && (
                            <>
                                <TableRow>
                                    <TableCell colSpan="5">
                                        <Segment>
                                            <Dimmer active inverted>
                                                <Loader active size="large">
                                                    {t('loading')}
                                                </Loader>
                                            </Dimmer>

                                            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                                        </Segment>
                                    </TableCell>
                                </TableRow>
                            </>
                        )}
                    </TableBody>
                </Table>
                <Pagination
                    floated="right"
                    defaultActivePage={1}
                    firstItem={null}
                    lastItem={null}
                    onPageChange={(e, data) => {
                        setPageNumber(data.activePage);
                        dispatch(getDifficulty({ pageSize: pageSize, pageNumber: data.activePage }));
                    }}
                    pointing
                    secondary
                    totalPages={Math.ceil(totalRecords / pageSize)}
                />
            </Container>
            <Modal closeIcon open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
                <ModalContent>
                    <Form>
                        <FormField>
                            <label>{t('title')}</label>
                            <input
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                placeholder={t('difficultyTitle')}
                            />
                        </FormField>
                        <FormField>
                            <label>{t('value')}</label>
                            <input
                                value={content}
                                onChange={e => setContent(e.target.value)}
                                placeholder={t('difficultyValue')}
                            />
                        </FormField>
                    </Form>
                </ModalContent>
                <ModalActions>
                    <Button color="red" onClick={() => setOpen(false)}>
                        <Icon name="remove" /> {t('cancel')}
                    </Button>
                    {modalType === 'add' ? (
                        <Button
                            color="green"
                            loading={addDifficultyState.spinner}
                            onClick={() => {
                                dispatch(addDifficulty({ title, content }));
                            }}
                        >
                            <Icon name="add" /> {t('addDifficulty')}
                        </Button>
                    ) : modalType === 'update' ? (
                        <Button
                            color="green"
                            loading={updateDifficultyState.spinner}
                            onClick={() => {
                                dispatch(updateDifficulty({ id: difficultyId, title: title, content: content }));
                            }}
                        >
                            <Icon name="save" /> {t('updateDifficulty')}
                        </Button>
                    ) : null}
                </ModalActions>
            </Modal>
        </div>
    );
}

export default Difficulties;
