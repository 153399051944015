import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'addProductionLine';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        productionLineData: null,
    };
}
export const addProductionLine = createAsyncThunk(
    'productionLine/add',
    async (
        {
            acquisitionID,
            base,
            baseType,
            content,
            difficultyID,
            maxTokens,
            questionTypeID,
            temperature,
            title,
            topP,
            topicID,
            withSolution,
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await api.post('/api/productionline/add', {
                acquisitionID,
                base,
                baseType,
                content,
                difficultyID,
                maxTokens,
                questionTypeID,
                temperature,
                title,
                topP,
                topicID,
                withSolution,
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const addProductionlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetAddProductionLine: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.productionLineData = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(addProductionLine.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(addProductionLine.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.productionLineData = action.payload;
            })
            .addCase(addProductionLine.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetAddProductionLine } = addProductionlice.actions;
export const addProductionLineReducer = addProductionlice.reducer;
