import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'getQuestionsByPLD';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        getQuestionsByPld: null,
    };
}

export const getQuestionByPld = createAsyncThunk(
    'question/getByPld',
    async ({ productionLineId, pageSize, pageNumber }, { rejectWithValue }) => {
        try {
            const response = await api.get(
                `/api/question/get/productionLine/${productionLineId}?pageSize=${pageSize}&pageNumber=${pageNumber}`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const getQuestionsByPLDSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetGetQuestionByPld: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.getQuestionsByPld = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getQuestionByPld.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(getQuestionByPld.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.getQuestionsByPld = action.payload;
            })
            .addCase(getQuestionByPld.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetGetQuestionByPld } = getQuestionsByPLDSlice.actions;
export const getQuestionsByPLDReducer = getQuestionsByPLDSlice.reducer;
