import Hello from './pages/Hello';
import Index from './pages/Index';
import NotFound from './pages/NotFound';
import Acquisitions from './pages/acquisition/Acquisitions';
import CreateCode from './pages/auth/CreateCode';
import VerifyCode from './pages/auth/VerifyCode';
import Difficulties from './pages/difficulty/Difficulties';
import AuthLayout from './pages/layout/AuthLayout';
import Header from './pages/layout/Header';
import ProductionLines from './pages/qreator/ProductionLines';
import Questions from './pages/qreator/Questions';
import QuestionTypes from './pages/questionType/QuestionTypes';
import Topics from './pages/topic/Topics';

const routes = [
    {
        path: '/',
        element: <Index />,
    },
    {
        path: '/hello',
        element: <Hello />,
    },
    {
        element: <AuthLayout />,
        children: [
            {
                path: 'login',
                element: <CreateCode />,
            },
            {
                path: 'verify-code',
                element: <VerifyCode />,
            },
        ],
    },
    {
        element: <Header />,
        children: [
            { path: 'difficulties', element: <Difficulties /> },
            { path: 'acquisitions', element: <Acquisitions /> },
            { path: 'topics', element: <Topics /> },
            { path: 'question-types', element: <QuestionTypes /> },
            { path: 'production-lines', element: <ProductionLines /> },
            { path: 'questions', element: <Questions /> },
        ],
    },
    {
        path: '*',
        element: <NotFound />,
    },
];

export default routes;
