import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'deleteTopic';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        deleteTopic: null,
    };
}

export const deleteTopic = createAsyncThunk('topic/delete', async (topicId, { rejectWithValue }) => {
    try {
        const response = await api.delete(`/api/topic/delete/${topicId}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const deleteTopicSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetDeleteTopic: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.deleteTopic = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(deleteTopic.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(deleteTopic.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.deleteTopic = action.payload;
            })
            .addCase(deleteTopic.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetDeleteTopic } = deleteTopicSlice.actions;
export const deleteTopicReducer = deleteTopicSlice.reducer;
