import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'getAcquisitionById';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        getAcquisitionById: null,
    };
}

export const getAcquisitionById = createAsyncThunk(
    'acquisition/getById',
    async (acquisitionId, { rejectWithValue }) => {
        try {
            const response = await api.get(`/api/acquisition/get/${acquisitionId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const getAcquisitionByIdSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetGetAcquisitionById: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.getAcquisitionById = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getAcquisitionById.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(getAcquisitionById.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.getAcquisitionById = action.payload;
            })
            .addCase(getAcquisitionById.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetGetAcquisitionById } = getAcquisitionByIdSlice.actions;
export const getAcquisitionByIdReducer = getAcquisitionByIdSlice.reducer;
