import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'deleteProductionLine';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        deleteProductionLine: null,
    };
}
export const deleteProductionLine = createAsyncThunk(
    'productionLine/delete',
    async (productionLineId, { rejectWithValue }) => {
        try {
            const response = await api.delete(`/api/productionline/delete/${productionLineId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const deleteProductionlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetDeleteProductionLine: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.deleteProductionLine = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(deleteProductionLine.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(deleteProductionLine.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.deleteProductionLine = action.payload;
            })
            .addCase(deleteProductionLine.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetDeleteProductionLine } = deleteProductionlice.actions;
export const deleteProductionLineReducer = deleteProductionlice.reducer;
