import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
    Button,
    Container,
    Dimmer,
    Dropdown,
    Form,
    FormField,
    Icon,
    Image,
    Loader,
    Message,
    MessageHeader,
    Modal,
    ModalActions,
    ModalContent,
    Pagination,
    Segment,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from 'semantic-ui-react';
import Swal from 'sweetalert2';
import { FormatZuluTime } from '../../helpers/Formatter';
import { addAcquisition, resetAddAcquisition } from '../../store/acquisition/addAcquisition.slice';
import { deleteAcquisition, resetDeleteAcquisition } from '../../store/acquisition/deleteAcquisition.slice';
import { getAcquisitions } from '../../store/acquisition/getAcquisitions.slice';
import { getAcquisitionsByParentId } from '../../store/acquisition/getAcquisitionsByParentId.slice';
import { resetUpdateAcquisition, updateAcquisition } from '../../store/acquisition/updateAcquisition.slice';

function Acquisition() {
    const dispatch = useDispatch();
    const addAcquisitionState = useSelector(state => state.addAcquisition);
    const deleteAcquisitionState = useSelector(state => state.deleteAcquisition);
    const getAcquisitionsState = useSelector(state => state.getAcquisitions);
    const getAcquisitionsByParentIdState = useSelector(state => state.getAcquisitionsByParentId);
    const updateAcquisitionState = useSelector(state => state.updateAcquisition);
    const [t] = useTranslation();

    const [open, setOpen] = useState(false);
    const [acquisition, setAcquisition] = useState('');
    const [description, setDescription] = useState('');
    const [parentId, setParentId] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [loadingId, setLoadingId] = useState(null); // Durumu tutacak olan state
    const [acquisitionId, setAcquisitionId] = useState(null);
    const [modalType, setModalType] = useState('add');
    const [totalRecords, setTotalRecords] = useState(0);
    // acquisitionsList, her seviyedeki acquisitionları ve onların çocuklarını tutacak
    const [acquisitionsList, setAcquisitionsList] = useState([]);
    let acquisitions = [];

    useEffect(() => {
        // İlk seviye acquisitionları yükle
        dispatch(getAcquisitions({ pageSize, pageNumber }));
    }, [dispatch, pageSize, pageNumber]);

    useEffect(() => {
        if (getAcquisitionsState.done) {
            // İlk seviye acquisitionları ayarla
            setAcquisitionsList([getAcquisitionsState.getAcquisitions.result.acquisition]);
        }
    }, [getAcquisitionsState]);

    useEffect(() => {
        if (getAcquisitionsByParentIdState.done) {
            const newAcquisitions = getAcquisitionsByParentIdState.getAcquisitionsByParentId.result?.acquisition;

            // Eğer yeni gelen bilgiler undefined ise veya boş bir dizi ise işlem yapma
            if (!newAcquisitions || newAcquisitions.length === 0) {
                return;
            }

            // Yalnızca yeni bilgiler varsa ve zaten eklenmemişse listeyi güncelle
            if (!acquisitionsList.some(list => list.some(acq => acq.ID === newAcquisitions[0].ID))) {
                setAcquisitionsList(prevList => [...prevList, newAcquisitions]);
            }
        }
    }, [getAcquisitionsByParentIdState]);

    useEffect(() => {
        if (updateAcquisitionState.done) {
            setOpen(false);
            setAcquisition('');
            setDescription('');
            setAcquisitionId(null);
            setLoadingId(null);
            setModalType('add');
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Başarılı',
                text: 'Acquisition başarıyla güncellendi',
                showConfirmButton: false,
                timer: 1500,
            });
            if (parentId) {
                dispatch(getAcquisitionsByParentId(parentId));
            } else {
                dispatch(getAcquisitions({ pageSize, pageNumber }));
            }
            dispatch(resetUpdateAcquisition());
            return;
        }
    }, [updateAcquisitionState]);

    useEffect(() => {
        if (addAcquisitionState.done) {
            setOpen(false);
            setAcquisition('');
            setDescription('');
            setAcquisitionId(null);
            setLoadingId(null);
            setModalType('add');
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Başarılı',
                text: 'Acquisition başarıyla eklendi',
                showConfirmButton: false,
                timer: 1500,
            });
            if (parentId) {
                dispatch(getAcquisitionsByParentId(parentId));
            } else {
                dispatch(getAcquisitions({ pageSize, pageNumber }));
            }
            dispatch(resetAddAcquisition());
            return;
        }
    }, [addAcquisitionState]);

    const handleDropdownChange = (index, value) => {
        const updatedList = acquisitionsList.slice(0, index + 1);
        setParentId(value);

        // Yeni acquisition'ların çocuklarını yüklemek için dispatch
        dispatch(getAcquisitionsByParentId(value));

        // Listeyi sadece yeni bilgi geldiğinde güncelle
        setAcquisitionsList(updatedList);
    };

    const handleDelete = id => {
        setLoadingId(id); // Silme işlemi başladığında, loadingId'yi güncelle
        dispatch(deleteAcquisition(id));
    };

    useEffect(() => {
        if (deleteAcquisitionState.done) {
            setLoadingId(null);
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Başarılı',
                text: 'Acquisition başarıyla silindi',
                showConfirmButton: false,
                timer: 1500,
            });
            if (parentId) {
                dispatch(getAcquisitionsByParentId(parentId));
            } else {
                dispatch(getAcquisitions({ pageSize, pageNumber }));
            }
            dispatch(resetDeleteAcquisition());
            return;
        }
    }, [deleteAcquisitionState]);

    if (getAcquisitionsByParentIdState?.done) {
        acquisitions = getAcquisitionsByParentIdState.getAcquisitionsByParentId.result.acquisition;
    } else if (getAcquisitionsState?.done) {
        acquisitions = getAcquisitionsState.getAcquisitions.result.acquisition;
    }

    return (
        <div>
            <Container style={{ marginTop: `7em` }}>
                <Message attached className="d-flex justify-content-between align-items-center">
                    <MessageHeader>{t('acquisitions')}</MessageHeader>
                    <Button>{t('addNewAcquisition')}</Button>
                </Message>
                <Form className="attached fluid segment">
                    {acquisitionsList.map((acquisitions, index) => (
                        <Dropdown
                            className="mt-3"
                            key={index}
                            placeholder={t('selectAcquisition')}
                            fluid
                            search
                            selection
                            options={acquisitions.map(acq => ({
                                key: acq.ID,
                                value: acq.ID,
                                text: acq.Acquisition,
                            }))}
                            onChange={(e, data) => handleDropdownChange(index, data.value)}
                        />
                    ))}
                </Form>

                <Table celled>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>Id</TableHeaderCell>
                            <TableHeaderCell>{t('acquisition')}</TableHeaderCell>
                            <TableHeaderCell>{t('description')}</TableHeaderCell>
                            <TableHeaderCell>{t('createdAt')}</TableHeaderCell>
                            <TableHeaderCell>{t('updatedAt')}</TableHeaderCell>

                            <TableHeaderCell textAlign="right"></TableHeaderCell>
                            <TableHeaderCell textAlign="right">
                                <Button
                                    onClick={() => {
                                        setModalType('add');
                                        setOpen(true);
                                    }}
                                    icon
                                >
                                    {t('addNewAcquisition')}
                                    <Icon name="add" />
                                </Button>
                            </TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {acquisitions.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.ID}</TableCell>
                                <TableCell>{item.Acquisition}</TableCell>
                                <TableCell>{item.Description}</TableCell>
                                <TableCell>{FormatZuluTime(item.CreatedAt)}</TableCell>
                                <TableCell>{FormatZuluTime(item.UpdatedAt)}</TableCell>
                                <TableCell textAlign="right">
                                    <Button
                                        color="yellow"
                                        onClick={() => {
                                            console.log('item', item);
                                            setAcquisitionId(item.ID);
                                            setAcquisition(item.Acquisition);
                                            setDescription(item.Description);
                                            setModalType('update');
                                            console.log('modalType', modalType);
                                            setOpen(true);
                                        }}
                                        icon
                                    >
                                        {t('update')}
                                    </Button>
                                </TableCell>
                                <TableCell textAlign="right">
                                    <Button
                                        color="red"
                                        onClick={() => handleDelete(item.ID)}
                                        icon
                                        loading={loadingId === item.ID && deleteAcquisitionState.spinner}
                                    >
                                        {t('delete')}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                        {getAcquisitionsByParentIdState.spinner && (
                            <>
                                <TableRow>
                                    <TableCell colSpan="5">
                                        <Segment>
                                            <Dimmer active inverted>
                                                <Loader active size="large">
                                                    Yükleniyor
                                                </Loader>
                                            </Dimmer>

                                            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                                        </Segment>
                                    </TableCell>
                                </TableRow>
                            </>
                        )}
                    </TableBody>
                </Table>
                <Pagination
                    floated="right"
                    defaultActivePage={1}
                    firstItem={null}
                    lastItem={null}
                    onPageChange={(e, data) => {
                        setPageNumber(data.activePage);
                        dispatch(getAcquisitions({ pageSize: pageSize, pageNumber: data.activePage }));
                    }}
                    pointing
                    secondary
                    totalPages={Math.ceil(totalRecords / pageSize)}
                />
            </Container>
            <Modal closeIcon open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
                <ModalContent>
                    <Form>
                        <FormField>
                            <label>{t('acquisition')}</label>
                            <input
                                value={acquisition}
                                onChange={e => setAcquisition(e.target.value)}
                                placeholder={t('acquisition')}
                            />
                        </FormField>
                        <FormField>
                            <label>{t('description')}</label>
                            <input
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                                placeholder={t('description')}
                            />
                        </FormField>
                    </Form>
                </ModalContent>
                <ModalActions>
                    <Button color="red" onClick={() => setOpen(false)}>
                        <Icon name="remove" /> {t('cancel')}
                    </Button>
                    {modalType === 'add' ? (
                        <Button
                            color="green"
                            loading={addAcquisitionState.spinner}
                            onClick={() => {
                                dispatch(addAcquisition({ acquisition, description, parentId }));
                            }}
                        >
                            <Icon name="add" /> {t('addAcquisition')}
                        </Button>
                    ) : modalType === 'update' ? (
                        <Button
                            color="green"
                            loading={updateAcquisitionState.spinner}
                            onClick={() => {
                                dispatch(updateAcquisition({ id: acquisitionId, parentId, acquisition, description }));
                            }}
                        >
                            <Icon name="save" /> {t('updateAcquisition')}
                        </Button>
                    ) : null}
                </ModalActions>
            </Modal>
        </div>
    );
}

export default Acquisition;
