import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const token = localStorage.getItem('accessToken');
const name = 'verifyCode';
const initialState = createInitialState();

export const verifyCode = createAsyncThunk('verifyCode', async ({ phoneNumber, verifyCode }, { rejectWithValue }) => {
    try {
        const response = await api.post('/api/verify', { phoneNumber, verifyCode });
        localStorage.setItem('accessToken', response.data.result.accessToken);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        isLogin: !!token,
    };
}

const verifyCodeSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        logout: () => {
            sessionStorage.clear();
            localStorage.clear();
            return initialState;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(verifyCode.pending, state => {
                state.done = false;
                state.error = false;
                state.spinner = true;
                state.isLogin = false;
            })
            .addCase(verifyCode.fulfilled, (state, action) => {
                state.verifyCode = action.payload;
                state.done = true;
                state.error = false;
                state.spinner = false;
                state.isLogin = true;
            })
            .addCase(verifyCode.rejected, (state, action) => {
                state.error = action.payload;
                state.done = false;
                state.spinner = false;
                state.isLogin = false;
            });
    },
});

export const { logout } = verifyCodeSlice.actions;
export const verifyCodeReducer = verifyCodeSlice.reducer;
