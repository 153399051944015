import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'addQuestion';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        addQuestion: null,
    };
}

export const addQuestion = createAsyncThunk(
    'question/add',
    async ({ content, options, rightAnswer, solution, productionLineId, isPublished }, { rejectWithValue }) => {
        try {
            const response = await api.post('/api/question/add', {
                content,
                options,
                rightAnswer,
                solution,
                productionLineId,
                isPublished,
            });
            return response.data; // Assuming the API returns the added question data
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const addQuestionSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetAddQuestion: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.addQuestion = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(addQuestion.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(addQuestion.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.addQuestion = action.payload;
            })
            .addCase(addQuestion.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetAddQuestion } = addQuestionSlice.actions;
export const addQuestionReducer = addQuestionSlice.reducer;
