export function FormatZuluTime(zuluTime) {
    // Zulu zamanını Date nesnesine dönüştürme
    const date = new Date(zuluTime);
  
    // Kullanıcının yerel diline ve zaman dilimine göre tarihi formatlama
    const options = {
      year: 'numeric', month: 'long', day: 'numeric',
      hour: 'numeric', minute: '2-digit', second: '2-digit',
      hour12: false // 24 saat formatını kullan
    };
  
    // Formatlanmış tarihi döndür
    return new Intl.DateTimeFormat('tr-TR', options).format(date);
  }
  