import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    tr: {
        translation: {
            homepage: 'Anasayfa',
            productionLine: 'Üretim Hattı',
            configuration: 'Konfigürasyon',
            logout: 'Çıkış Yap',
            categories: 'Kategoriler',
            topic: 'Konu',
            acquisition: 'Edinim',
            questionType: 'Soru Tipi',
            difficulty: 'Zorluk',
            lines: 'Hatlar',
            question: 'Soru',
            qreatorConfig: 'Qreator Konfigürasyon',
            qreator: 'Qreator',
            topics: 'Konular',
            addNewParent: 'Yeni Üst Kategori Ekle',
            selectTopic: 'Konu Seç',
            addTopic: 'Konu Ekle',
            title: 'Başlık',
            description: 'Açıklama',
            createdAt: 'Oluşturulma Tarihi',
            updatedAt: 'Güncellenme Tarihi',
            update: 'Güncelle',
            delete: 'Sil',
            cancel: 'İptal',
            updateTopic: 'Konuyu Güncelle',
            addParent: 'Üst Kategori Ekle',
            acquisitions: 'Edinimler',
            addNewAcquisition: 'Yeni Edinim Ekle',
            selectAcquisition: 'Edinim Seç',
            addAcquisition: 'Edinim Ekle',
            updateAcquisition: 'Edinimi Güncelle',
            addQuestionType: 'Soru Tipi Ekle',
            loading: 'Yükleniyor...',
            updateQuestionType: 'Soru Tipini Güncelle',
            content: 'İçerik',
            value: 'Değer',
            addDifficulty: 'Zorluk Ekle',
            updateDifficulty: 'Zorluğu Güncelle',
            difficultyTitle: 'Zorluk Başlığı',
            difficultyValue: 'Zorluk Değeri',
            addProductionLine: 'Üretim Hattı Ekle',
            addNewProductionLine: 'Yeni Üretim Hattı Ekle',
            numberOfQuestions: 'Soru Sayısı',
            questionProductionLines: 'Soru Üretim Hatları',
            create: 'Oluştur',
            withSolution: 'Çözüm ile',
            base: 'Taban',
            baseType: 'Taban Tipi',
            maxTokens: 'Maksimum Token',
            yes: 'Evet',
            no: 'Hayır',
            selectDifficulty: 'Zorluk Seç',
            selectQuestionType: 'Soru Tipi Seç',
            creatorBaseType: 'Oluşturucu Taban Tipi',
            updateNewProductionLine: 'Üretim Hattını Güncelle',
        },
    },
    en: {
        translation: {
            homepage: 'Homepage',
            productionLine: 'Production Line',
            configuration: 'Configuration',
            logout: 'Logout',
            categories: 'Categories',
            topic: 'Topic',
            acquisition: 'Acquisition',
            questionType: 'Question Type',
            difficulty: 'Difficulty',
            lines: 'Lines',
            question: 'Question',
            qreatorConfig: 'Qreator Config',
            qreator: 'Qreator',
            topics: 'Topics',
            addNewParent: 'Add New Parent',
            selectTopic: 'Select Topic',
            addTopic: 'Add Topic',
            title: 'Title',
            description: 'Description',
            createdAt: 'Created At',
            updatedAt: 'Updated At',
            update: 'Update',
            delete: 'Delete',
            cancel: 'Cancel',
            updateTopic: 'Update Topic',
            addParent: 'Add Parent',
            acquisitions: 'Acquisitions',
            addNewAcquisition: 'Add New Acquisition',
            selectAcquisition: 'Select Acquisition',
            addAcquisition: 'Add Acquisition',
            updateAcquisition: 'Update Acquisition',
            addQuestionType: 'Add Question Type',
            loading: 'Loading...',
            updateQuestionType: 'Update Question Type',
            content: 'Content',
            value: 'Value',
            addDifficulty: 'Add Difficulty',
            updateDifficulty: 'Update Difficulty',
            difficultyTitle: 'Difficulty Title',
            difficultyValue: 'Difficulty Value',
            addProductionLine: 'Add Production Line',
            addNewProductionLine: 'Add New Production Line',
            numberOfQuestions: 'Number of Questions',
            questionProductionLines: 'Question Production Lines',
            create: 'Create',
            withSolution: 'With Solution',
            base: 'Base',
            baseType: 'Base Type',
            maxTokens: 'Max Tokens',
            yes: 'Yes',
            no: 'No',
            selectDifficulty: 'Select Difficulty',
            selectQuestionType: 'Select Question Type',
            creatorBaseType: 'Creator Base Type',
            updateNewProductionLine: 'Update Production Line',
        },
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'tr',
});

export default i18n;
