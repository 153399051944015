import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router';

function AuthLayout() {
    const navigate = useNavigate();
    const verifyCodeState = useSelector(state => state.verifyCode);
    useEffect(() => {
        if (verifyCodeState.isLogin) {
            navigate('/');
        }
    }, [verifyCodeState.isLogin, navigate]);

    return (
        <div>
            <Outlet />
        </div>
    );
}

export default AuthLayout;
