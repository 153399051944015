import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'updateAcquisition';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        updateAcquisition: null,
    };
}

export const updateAcquisition = createAsyncThunk(
    'acquisition/update',
    async ({ id, parentId, acquisition, description }, { rejectWithValue }) => {
        try {
            const response = await api.put('/api/acquisition/update', {
                id,
                parentId,
                acquisition,
                description,
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const updateAcquisitionSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetUpdateAcquisition: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.updateAcquisition = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(updateAcquisition.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(updateAcquisition.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.updateAcquisition = action.payload;
            })
            .addCase(updateAcquisition.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetUpdateAcquisition } = updateAcquisitionSlice.actions;
export const updateAcquisitionReducer = updateAcquisitionSlice.reducer;
