import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'getTopicByParentId';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        getTopicByParentId: null,
    };
}

export const getTopicByParentId = createAsyncThunk('topic/getByParentId', async (topicId, { rejectWithValue }) => {
    try {
        const response = await api.get(`/api/topic/get/parent/${topicId}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const getTopicByParentIdSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetGetTopicByParentId: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.getTopicByParentId = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getTopicByParentId.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(getTopicByParentId.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.getTopicByParentId = action.payload;
            })
            .addCase(getTopicByParentId.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetGetTopicByParentId } = getTopicByParentIdSlice.actions;
export const getTopicByParentIdReducer = getTopicByParentIdSlice.reducer;
