import React from 'react';
import Header from './layout/Header';

function Index() {
    return (
        <div>
            <Header />
        </div>
    );
}

export default Index;
