import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'updateQuestionType';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        updateQuestionType: null,
    };
}

export const updateQuestionType = createAsyncThunk(
    'questionType/update',
    async ({ id, title, content }, { rejectWithValue }) => {
        try {
            const response = await api.put('/api/questiontype/update', { id, title, content });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const updateQuestionTypeSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetUpdateQuestionType: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.updateQuestionType = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(updateQuestionType.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(updateQuestionType.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.updateQuestionType = action.payload;
            })
            .addCase(updateQuestionType.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetUpdateQuestionType } = updateQuestionTypeSlice.actions;
export const updateQuestionTypeReducer = updateQuestionTypeSlice.reducer;
