import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import App from './App';
import './i18n.js';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store.js';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <BrowserRouter basename='/'>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>
);

reportWebVitals();
