import axios from 'axios';
import { useDispatch } from 'react-redux';
import { API_BASE } from '../config/env';
import { logout } from '../store/auth/verifyCode.slice';

const api = axios.create({
    baseURL: API_BASE,
    headers: {
        'Accept-Language': 'tr-tr',
        'Content-Type': 'application/json',
    },
});
api.interceptors.request.use(
    config => {
        const token = localStorage.getItem('accessToken');

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
api.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response && error.response.status === 401) {
            useDispatch(logout());
        }
        return Promise.reject(error);
    }
);
export default api;
