import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'deleteQuestionType';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        deleteQuestionType: null,
    };
}

export const deleteQuestionType = createAsyncThunk('questionType/delete', async (id, { rejectWithValue }) => {
    try {
        const response = await api.delete(`/api/questiontype/delete/${id}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const deleteQuestionTypeSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetDeleteQuestionType: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.deleteQuestionType = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(deleteQuestionType.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(deleteQuestionType.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.deleteQuestionType = action.payload;
            })
            .addCase(deleteQuestionType.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetDeleteQuestionType } = deleteQuestionTypeSlice.actions;
export const deleteQuestionTypeReducer = deleteQuestionTypeSlice.reducer;
