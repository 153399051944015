import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'getQuestionTypeById';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        getQuestionTypeById: null,
    };
}

export const getQuestionTypeById = createAsyncThunk('questionType/getById', async (id, { rejectWithValue }) => {
    try {
        const response = await api.get(`/api/questiontype/get/${id}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const getQuestionTypeByIdSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetGetQuestionTypeById: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.getQuestionTypeById = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getQuestionTypeById.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(getQuestionTypeById.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.getQuestionTypeById = action.payload;
            })
            .addCase(getQuestionTypeById.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetGetQuestionTypeById } = getQuestionTypeByIdSlice.actions;
export const getQuestionTypeByIdReducer = getQuestionTypeByIdSlice.reducer;
